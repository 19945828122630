<template>
  <div class="main-wrapper">
    <!-- Navigation -->
    <header>
      <div class="navbar-background" :class="{ 'rzecommerce-navbar-background': isRzecommerce2024Event }"></div>
      <nav-bar></nav-bar>
      <!--      <resucytacja-event-info-component v-if="resucytacjaMenuOpen"></resucytacja-event-info-component>-->
    </header>

    <!-- Content -->
    <main class="content-wrapper">
      <router-view></router-view>
    </main>

    <!-- Footer -->
    <footer-view></footer-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import NavBar from "@/web/components/navbar/NavBar";
import FooterView from "@/shared/components/Footer";
import Constants from "@/web/constants";

export default {
  name: "Main",

  data() {
    return {
      resucytacjaMenuOpen: false,
    };
  },

  components: {
    NavBar,
    FooterView,
  },

  beforeMount() {
    try {
      this.checkAccessCodeValidity();
      this.checkLoginRequirement();
    } catch (err) {
      console.error(err);
    }
  },

  mounted() {
    const pathname = window.location.pathname;
    if (pathname.includes("konferencja-antymobbingowa")) {
      let url = "https://landing.meetingapplication.com/event/i-ogolnopolska-konferencja-razem-przeciw-mobbingowi";
      window.open(url, "_self").focus();
    }
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'style') {
          document.body.style.paddingTop = '';
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['style']
    });
  },

  methods: {
    ...mapActions(["logout"]),
    ...mapActions("accessCode", ["clearAccessCode"]),

    checkAccessCodeValidity() {
      // Redirect on required access code
      if (this.requiresAccessCode && !this.isAccessCodeValid) {
        this.redirect(Constants.ROUTE_ACCESS_CODE);
        throw new Error("Event requires access code");
      }
    },

    checkLoginRequirement() {
      // Redirect on required user login
      if (this.requiresLogin && !this.isEventJoined) {
        this.clearAccessCode();
        this.redirect(Constants.ROUTE_LOGIN);
        throw new Error("Event requires active login");
      }
    },

    redirect(routeName) {
      const query = { returnUrl: this.$route.fullPath };
      this.$router.push({ name: routeName, query });
    },
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["requiresLogin", "requiresAccessCode"]),
    ...mapGetters("auth", {
      isEventJoined: "isJoined",
    }),
    ...mapGetters("accessCode", {
      isAccessCodeValid: "isValid",
    }),

    isRzecommerce2024Event() {
      return this.event.id === 1643 || this.event.id === 104;
    },
  },

  watch: {
    isAccessCodeValid(to, from) {
      if (to === false && from === true) {
        // TODO: Add modal about access code change
        try {
          this.checkAccessCodeValidity();
        } catch (err) {
          console.error(err);
        }
      }
    },

    isEventJoined(to, from) {
      if (to === false && from === true) {
        // TODO: Add modal about session expiration
        try {
          this.checkLoginRequirement();
        } catch (err) {
          console.error(err);
        }
      }
    },
  },
};
</script>
