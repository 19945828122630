<template>
  <div :id="eventWrapperId" class="event-wrapper">
    <!-- Failed to load event -->
    <section v-if="error" class="hero is-danger">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            {{ $t("system.event_load_failed") }}
          </h1>
          <h2 class="subtitle">
            {{ error }}
          </h2>
        </div>
      </div>
    </section>

    <!-- Event loading indicator -->
    <template v-else-if="isRequesting || !eventId">
      <b-loading :active="true"></b-loading>
    </template>

    <!-- Event is ready to display -->
    <template v-else>
      <router-view></router-view>
      <notifications-hub></notifications-hub>

      <template v-if="isAuthorized && !isUpgrade2023Event && !isPismEvent && !isPedEvent">
        <side-menu-bubbles v-if="isSideMenuBubbles"></side-menu-bubbles>
        <side-menu-panel v-else></side-menu-panel>
      </template>
      <admin-panel v-if="lpConfiguratorEnabled"></admin-panel>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import RequestMixin from "@/shared/mixins/RequestMixin";
import VueGTagMixin from "@/web/mixins/VueGTagMixin";
import NotificationsHub from "@/web/components/notifications/NotificationsHub";
import SideMenuBubbles from "@/web/components/sidemenu/SideMenuBubbles";
import SideMenuPanel from "@/web/components/sidemenu/SideMenuPanel";
import AdminPanel from "@/web/components/admin/AdminPanel";
import Constants from "@/web/constants";
import LpConfigConstants from "@/shared/constants/lp-config-constants";
import { setGTagScripts } from "@/shared/utils/gtag-utils";

export default {
  mixins: [RequestMixin, VueGTagMixin],

  name: "Event",

  components: {
    SideMenuBubbles,
    SideMenuPanel,
    NotificationsHub,
    AdminPanel,
  },

  data() {
    return {
      customILoveMarketingRunning: false,
      autoLogOutTimeout: null,
      timerRunning: false,
      timerTimeout: null,
    };
  },

  requests: {
    async requestEventLoad() {
      let slug = this.$route.params.slug;
      let magicLinkToken = this.$route.query.magic_link;
      if (slug === undefined) {
        await this.initEvent({eventSlug: "rzecommerce", magicLinkToken: magicLinkToken});
      } else {
        await this.initEvent({eventSlug: slug, magicLinkToken: magicLinkToken});
      }
    },
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    ...mapActions(["initEvent"]),
    ...mapActions("auth", ["refreshToken"]),
    ...mapActions("locales", ["initLocale", "setConstantLocale"]),
    ...mapMutations(["updateCurrentTime", "setAskUserToSubmitMetadata"]),

    startTimer() {
      this.timerRunning = true;
      this.updateTimer();
    },

    stopTimer() {
      this.timerRunning = false;
      if (this.timerTimeout) {
        clearTimeout(this.timerTimeout);
      }
    },

    updateTimer() {
      if (this.timerRunning) {
        this.updateCurrentTime();
        const currentTime = new Date();
        const nextMinute = new Date(currentTime.getTime());
        nextMinute.setMinutes(nextMinute.getMinutes() + 1, 1, 0);
        this.timerTimeout = setTimeout(() => this.updateTimer(), nextMinute.getTime() - currentTime.getTime());
      }
    },

    startAutoLogOutFeature() {
      if (this.autoLogOutEnabled) {
        this.customILoveMarketingRunning = true;
        this.nextUpdate();
      }
    },

    stopAutoLogOutFeature() {
      this.customILoveMarketingRunning = false;
      if (this.autoLogOutTimeout) {
        clearTimeout(this.autoLogOutTimeout);
      }
    },

    nextUpdate() {
      if (this.customILoveMarketingRunning) {
        this.autoLogOutTimeout = setTimeout(() => {
          this.refreshToken(this.eventId);
          this.nextUpdate();
        }, 5 * 60 * 1000);
      }
    },
  },

  computed: {
    ...mapState(["event", "eventId", "eventColors", "askUserToSubmitMetadata"]),
    ...mapGetters(["lpSettings", "autoLogOutEnabled", "lpConfiguratorEnabled", "sideMenuType"]),
    ...mapGetters("auth", ["isAuthorized"]),
    ...mapGetters("currentUser", { currentUserUuid: "uuid" }),
    ...mapGetters("sideMenu", ["isSideMenuPanelOpen"]),

    LpConfigConstants: () => LpConfigConstants,

    eventWrapperId() {
      const eventId = this.eventId;
      return `event-${eventId}`;
    },

    isUpgrade2023Event() {
      return this.eventId === 1355;
    },

    isPismEvent() {
      return this.event.id === 1312;
    },

    isPedEvent() {
      return this.event.id === 1419;
    },

    isSideMenuBubbles() {
      return this.sideMenuType === LpConfigConstants.SIDE_MENU_TYPES.BUBBLES;
    },
  },

  watch: {
    // Watch for route slug param changes
    // Reload the event on event change
    "$route.params.slug": {
      handler: function (eventSlug) {
        console.log(eventSlug);
        if(eventSlug) {
          this.initLocale(eventSlug).then(initializedLocale => {
            this.$setLocale(initializedLocale.localeCode);
            this.requestEventLoad();
            document.documentElement.lang = initializedLocale.localeCode;
          });
        } else if(eventSlug === undefined) {
          this.initLocale("").then(initializedLocale => {
            this.$setLocale(initializedLocale.localeCode);
            this.requestEventLoad();
            document.documentElement.lang = initializedLocale.localeCode;
          });
        }
        // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.PAGE_VIEW, {
        //   [Constants.ANALYTICS_PARAMETERS.PAGE_TITLE]: eventSlug,
        // });
      },
      immediate: true,
    },

    lpSettings(newVal) {
      if (newVal) {
        const themeColors = newVal[LpConfigConstants.ROOT_FIELDS.THEME] || this.eventColors;
        this.$setThemeColors(themeColors);

        const customTranslations = newVal[LpConfigConstants.ROOT_FIELDS.TRANSLATIONS];
        this.$mergeCustomTranslations(customTranslations, "custom");

        const constantLocale = newVal.language;
        if (constantLocale) {
          this.setConstantLocale(constantLocale);
          this.$setLocale(constantLocale);
          document.documentElement.lang = constantLocale;
        }
      }
    },

    eventId: {
      handler: function (eventId) {
        if (eventId) {
          setGTagScripts(eventId);
          // setUserProperties(getAnalytics(), {
          //   [Constants.ANALYTICS_PROPERTIES.EVENT_ID]: eventId,
          //   [Constants.ANALYTICS_PROPERTIES.DEVICE_ID]: Constants.API_HEADER_DEVICE_ID,
          // });
        }
      },
      immediate: true,
    },

    event: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.$setOpenGraphTags(newValue);
        }
      },
    },

    currentUserUuid: {
      handler: function (currentUserUuid) {
        if (currentUserUuid) {
          // setUserId(getAnalytics(), currentUserUuid);
          this.startAutoLogOutFeature();
        } else {
          // setUserId(getAnalytics(), null);
          this.stopAutoLogOutFeature();
        }
      },
      immediate: true,
    },

    askUserToSubmitMetadata: {
      handler: function (newValue) {
        if (newValue) {
          this.$root.openUserSpecificFieldsModal();
          this.setAskUserToSubmitMetadata(false);
        }
      },
    },

    error: {
      handler: function (newValue) {
        //handling admin/attendee access to not published event
        if (newValue && newValue.response && newValue.response.status === 403 && !this.isAuthorized) {
          this.error = null;
          this.$router.replace({ name: Constants.ROUTE_LOGIN });
        } else if (newValue && newValue.response.data.errors && newValue.response.data.errors.length) {
          const errorCode = newValue.response.data.errors[0].code;
          if (errorCode === Constants.REST_MISSING_SYSTEM_CONSENTS_ERROR) {
            this.$root.openMissingSystemConsentsModal();
            this.error = null;
          } else {
            this.$root.openErrorModal(newValue);
            this.error = null;
          }
        }
      },
    },
  },
};
</script>
